import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import './sign-in-dialog.scss';

const SignInDialog = forwardRef((_props, ref: ForwardedRef<HTMLDialogElement | null>) => {
  const innerRef = useRef<HTMLDialogElement>(null);
  useImperativeHandle(ref, () => innerRef.current!, [innerRef]);
  const closePicker = useCallback(() => innerRef.current?.close(), [innerRef]);
  return (
    <dialog className="sign-in-dialog" ref={innerRef}>
      <button
        className="sign-in-dialog__dialog-close"
        onClick={closePicker}
      >
        <span className="material-symbols-outlined">close</span>
      </button>
    </dialog>
  );
});

export default SignInDialog;
