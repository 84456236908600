import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth, useAuthToken } from '@site/src/hooks/auth';
import BrowserOnly from '@docusaurus/BrowserOnly';
import clsx from 'clsx';
import SignInDialog from '../sign-in-dialog/sign-in-dialog';
import { useLocalStorage } from '@site/src/hooks/local-storage';

import './domain-picker.scss';

const OKTA_CLIENT_ID = 'reference-docs-site';

function getClientDomainFromHost(hostname: string): string | undefined {
  if(!hostname.includes('.lusid.com')) {
    return;
  }
  const clientDomain = hostname.split('.lusid.com')[0];
  // treat www as "no domain" for users at the top level
  return clientDomain === 'www' ? undefined : clientDomain;
}

function useDomain(): [string | undefined, (val: string) => void, boolean] {
  const urlDomain = useMemo(() => getClientDomainFromHost(window.location.host), []);
  const queryParamsDomain = useMemo(() => new URLSearchParams(window.location.search).get('domain'), []);
  const [domain, setDomain] = useLocalStorage<string | undefined>('lusid-support-domain');
  useEffect(() => {
    setDomain(urlDomain || queryParamsDomain || domain);
  }, []);
  return [domain, setDomain, !!urlDomain];
}

const SimpleLogin = ({ domain }: { domain: string }) => {
  const signInDialogRef = useRef<HTMLDialogElement>(null);
  const [authError, isLoading, isReady, login, logout] = useAuth(
    OKTA_CLIENT_ID,
    domain,
    signInDialogRef,
  );
  const isLoggedIn = !!useAuthToken();
  return (
    <div className="domain-picker">
      <button
        className="domain-picker__nav-button"
        disabled={isLoading || !isReady}
        title={authError}
        onClick={isLoggedIn ? logout : login}
      >
        <span
          className={clsx({
            'material-symbols-outlined domain-picker__nav-icon': true,
            'domain-picker__nav-icon_error': authError,
            'domain-picker__nav-icon_loading': domain && isLoading,
          })}
        >
          { authError ? 'error' : 'person' }
        </span>
        { domain && isLoggedIn ? 'Log out' : 'Log in' }
      </button>
      <SignInDialog ref={signInDialogRef} />
    </div>
  );
};

interface CustomDomainPickerProps {
  domain: string | undefined;
  isDomainLocked: boolean;
  onDomainSet: (domain: string) => void;
}

const CustomDomainPicker = ({
  domain,
  isDomainLocked,
  onDomainSet,
}: CustomDomainPickerProps) => {
  const signInDialogRef = useRef<HTMLDialogElement>(null);
  const [authError, isLoading, isReady, login, logout, retryCheckAddress] = useAuth(
    OKTA_CLIENT_ID,
    domain,
    signInDialogRef,
  );
  const isLoggedIn = !!useAuthToken();
  const pickerRef = useRef<HTMLDialogElement>(null);
  const [pendingDomain, setPendingDomain] = useState(domain || '');
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const onDomainSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(ev => {
    ev.preventDefault();
    if(domain === pendingDomain) {
      retryCheckAddress();
    } else {
      onDomainSet(pendingDomain);
    }
  }, [pendingDomain, onDomainSet]);
  useEffect(() => {
    if(domain !== pendingDomain) {
      setPendingDomain(domain || '');
    }
  }, [domain]);
  useEffect(() => {
    if(isPickerVisible) {
      pickerRef.current?.showModal();
    } else {
      pickerRef.current?.close();
    }
  }, [isPickerVisible, pickerRef]);
  const onPendingDomainChange = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setPendingDomain(ev.target.value);
  }, [setPendingDomain]);
  const openPicker = useCallback(() => setIsPickerVisible(true), [setIsPickerVisible]);
  const closePicker = useCallback(() => pickerRef.current?.close(), [pickerRef]);
  const onDialogClose = useCallback(() => setIsPickerVisible(false), [setIsPickerVisible]);
  return (
    <div className="domain-picker">
      <button
        className="domain-picker__nav-button"
        title={isLoggedIn ? `Logged in and viewing documentation for ${domain}.lusid.com` : 'Log in to view documentation tailored to your LUSID instance and try out API endpoints'}
        onClick={openPicker}
      >
        <span
          className={clsx({
            'material-symbols-outlined domain-picker__nav-icon': true,
            'domain-picker__nav-icon_loading': domain && isLoading,
          })}
        >
          person
        </span>
        { domain && isLoggedIn ? 'Logged in' : 'Log in' }
      </button>
      <dialog
        ref={pickerRef}
        className="domain-picker__dialog"
        onClose={onDialogClose}
      >
        <h1 className="domain-picker__dialog-heading">Log in</h1>
        <ol>
          <li>
            <p>Enter your LUSID environment's address</p>
            <form className="domain-picker__domain" onSubmit={onDomainSubmit}>
              <label className="domain-picker__domain-input-wrapper">
                <input
                  className="domain-picker__domain-input"
                  disabled={isDomainLocked}
                  placeholder="my-domain"
                  value={pendingDomain}
                  onChange={onPendingDomainChange}
                />
                <span className="domain-picker__domain-input-placeholder">.lusid.com</span>
                { pendingDomain && pendingDomain === domain && (
                  <span
                    className={clsx({
                      'material-symbols-outlined domain-picker__domain-submit-icon': true,
                      'domain-picker__domain-submit-icon_loading': isLoading,
                      'text--success': domain && !isLoading && !authError,
                      'text--danger': domain && !isLoading && authError,
                    })}
                  >
                    { domain && !isLoading && authError ? 'error' : 'check' }
                  </span>
                ) }
              </label>
              <button
                className={clsx({
                  'domain-picker__domain-submit button button--outline': true,
                  'button--success': pendingDomain !== domain,
                  'button--secondary': pendingDomain === domain,
                })}
                disabled={pendingDomain === domain && !authError}
              >
                Check address
              </button>
            </form>
            <p className="domain-picker__error">{ authError }</p>
          </li>
          <li>
            <p>Log in to <strong>{ domain || 'my-domain' }.lusid.com</strong></p>
            <button
              className={clsx({
                'domain-picker__login button button--outline': true,
                'button--primary': !isLoggedIn,
                'button--secondary': isLoggedIn,
              })}
              disabled={isLoading || !isReady || pendingDomain !== domain}
              onClick={isLoggedIn ? logout : login}
            >
              <span className="material-symbols-outlined">{ isLoggedIn ? 'logout' : 'lock_open' }</span>
              <span>{ isLoggedIn ? 'Log out' : 'Log in' }</span>
            </button>
          </li>
        </ol>
        <SignInDialog ref={signInDialogRef} />
        <button
          className="domain-picker__dialog-close"
          onClick={closePicker}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </dialog>
    </div>
  );
};

const DomainPicker = () => {
  const [domain, setDomain, isDomainLocked] = useDomain();
  if(isDomainLocked && domain) {
    return (
      <SimpleLogin domain={domain} />
    );
  }
  return (
    <CustomDomainPicker
      domain={domain}
      isDomainLocked={isDomainLocked}
      onDomainSet={setDomain}
    />
  );
};

export default () => <BrowserOnly>{ () => <DomainPicker /> }</BrowserOnly>;
