import { useEffect, useState } from 'react';
import _ from 'lodash';
import ExecutionEnvironment from '@docusaurus/ExecutionEnvironment';

function getValueFromStorage<T>(key: string): T | undefined {
  const saved = localStorage.getItem(key);
  if(saved) {
    const initial = JSON.parse(saved);
    return initial;
  }
  // key doesn't exist in storage yet
  return;
}

export function useLocalStorage<T>(key: string): [T | undefined, (val: T) => void] {
  if(!ExecutionEnvironment.canUseDOM) {
    return [undefined, () => {}];
  }
  const [value, setValue] = useState(() => getValueFromStorage<T>(key));
  useEffect(() => {
    if(_.isNil(value)) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
    document.dispatchEvent(new CustomEvent('storageupdate', { bubbles: true, detail: { key } }));
  }, [key, value]);
  useEffect(() => {
    const onStorageChange = (ev: CustomEvent<{ key: string }>) => {
      if(ev.detail.key === key) {
        const storedValue = getValueFromStorage<T>(key);
        // only update if stored value is actually different (_.isEqual does deep comparison)
        if(!_.isEqual(value, storedValue)) {
          setValue(storedValue);
        }
      }
    };
    document.addEventListener('storageupdate', onStorageChange);
    return () => document.removeEventListener('storageupdate', onStorageChange);
  }, [key, value, setValue]);
  return [value, setValue];
};

declare global {
  interface GlobalEventHandlersEventMap {
    storageupdate: CustomEvent<{ key: string }>;
  }
};
