import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import useDocusaurusContext from '@docusaurus/useDocusaurusContext';

export enum SearchFilter {
  All = '',
  API = 'api',
  ExcelAddin = 'excel',
};

export enum SearchResultType {
  APIEndpoint = 'api-endpoint',
  APISchema = 'api-schema',
  ExcelFunction = 'excel-function',
}

interface PagefindIndexOptions {
  mergeFilter: { resource: string };
  ranking: object;
}

interface Pagefind {
  destroy: () => Promise<void>;
  init: () => Promise<void>;
  mergeIndex: (path: string, options: PagefindIndexOptions) => Promise<void>;
  options: (options: PagefindIndexOptions) => Promise<void>;
  debouncedSearch: (query: string, options: any, debounce?: number) => any | null;
}

interface PagefindStatistics {
  counts: Record<SearchFilter, number>;
}

interface PagefindRawSearchResult {
  results: Array<object>;
  unfilteredResultCount: number;
  totalFilters: {
    resource: {
      api: number;
      excel: number;
    };
  };
}

interface PagefindSubResult {
  anchor: PagefindAnchor;
  excerpt: string;
  locations: number[];
  title: string;
  url: string;
}

interface PagefindAnchor {
  element: string;
  id: string;
  location: number;
  text: string;
}

export interface PagefindResult {
  anchors: PagefindAnchor[];
  content: string;
  excerpt: string;
  locations: number[];
  meta: {
    title: string;
    type?: string;
  };
  sub_results: Array<PagefindSubResult>;
  url: string;
}

export interface SearchItem {
  excerpt: string | null;
  subResultCount?: number;
  isSubResult?: boolean;
  type: SearchResultType | null;
  title: string;
  url: string;
}

const pagefindRanking = {
  pageLength: 0.1, // don't favour shorter documents
  termFrequency: 0.2, // favour articles with query in headings
  termSaturation: 0, // don't favour articles with many instances of the term
};

function getResultType(result: PagefindResult) {
  const resultType = result.meta.type;
  if(resultType === SearchResultType.APIEndpoint) {
    return SearchResultType.APIEndpoint;
  }
  if(resultType === SearchResultType.APISchema) {
    return SearchResultType.APISchema;
  }
  if(resultType === SearchResultType.ExcelFunction) {
    return SearchResultType.ExcelFunction;
  }
  if(_.includes(_.values(SearchResultType), resultType)) {
    return resultType as SearchResultType;
  }
  return null;
}

function isTitleOnlyResult(result: PagefindResult) {
  return result.locations.length === 1 && result.locations[0] === 0;
}

function isTitleOnlySubResult(result: PagefindSubResult) {
  const anchorLocation = result.anchor ? result.anchor.location : 0;
  return result.locations.length === 1 && result.locations[0] === anchorLocation;
}

function mergeIndex(pagefind: Pagefind, baseUrl: string, resource: string) {
  return pagefind.mergeIndex(`${baseUrl}pagefind-${resource}`, {
    mergeFilter: { resource },
    ranking: pagefindRanking,
  }).catch(err => console.warn(`Failed to initialise ${resource} search`, err));
}

export function usePagefind(): any {
  const [pagefind, setPagefind] = useState<Pagefind>();
  const docusaurusContext = useDocusaurusContext();
  const baseUrl = docusaurusContext.siteConfig.baseUrl;
  useEffect(() => {
    (async () => {
      const importPath = `${baseUrl}pagefind-api/pagefind.js`;
      const pagefind: Pagefind = await import(/* webpackIgnore: true */ importPath);
      await pagefind.destroy();
      await pagefind.init();
      await pagefind.options({
        mergeFilter: { resource: 'api' },
        ranking: pagefindRanking,
      });
      await mergeIndex(pagefind, baseUrl, 'excel');
      setPagefind(pagefind);
    })();
  }, []);
  return pagefind;
}

export function useSearch(
  query: string,
  resource = SearchFilter.All,
  pageSize = 5,
  subResultCount = 5,
): [SearchItem[], PagefindStatistics, (() => void) | null] {
  const pagefind = usePagefind();
  const [result, setResult] = useState<PagefindRawSearchResult | null>(null);
  const [items, setItems] = useState<SearchItem[]>([]);
  const [loadCount, setLoadCount] = useState(pageSize);
  useEffect(() => {
    if(!pagefind) {
      setResult(null);
      return;
    }
    (async () => {
      const searchResult = await pagefind.debouncedSearch(
        query,
        resource ? { filters: { resource } } : undefined,
        50,
      );
      if(searchResult !== null) {
        setResult(searchResult);
        setLoadCount(pageSize);
      }
    })();
  }, [pagefind, query, resource, setResult, setLoadCount]);
  const stats = useMemo<PagefindStatistics>(() => ({
    counts: {
      [SearchFilter.All]: result?.unfilteredResultCount ?? 0,
      [SearchFilter.API]: result?.totalFilters.resource?.api ?? 0,
      [SearchFilter.ExcelAddin]: result?.totalFilters.resource?.excel ?? 0,
    },
  }), [result]);
  const showMore = useMemo(
    () => loadCount < stats.counts[resource] ? () => setLoadCount(loadCount + pageSize) : null,
    [loadCount, pageSize, resource, stats, setLoadCount],
  );
  useEffect(() => {
    if(!result) {
      setItems([]);
      return;
    }
    const loadPromises = _.invokeMap(_.slice(result.results, 0, loadCount), 'data');
    Promise.all<PagefindResult>(loadPromises).then(loadedResults => {
      setItems(_.transform<PagefindResult, SearchItem[]>(loadedResults, (acc, result) => {
        const subResults = _.slice(result.sub_results, 0, subResultCount);
        const type = getResultType(result);
        if(subResults.length === 1 && result.sub_results[0].url === result.url) {
          // only hit is top of an article itself, show single item with title
          acc.push({
            type: type,
            title: result.meta.title,
            url: result.url,
            excerpt: isTitleOnlyResult(result) ? null : result.excerpt,
          });
          return;
        }
        acc.push({
          type: type,
          title: result.meta.title,
          url: result.url,
          // don't show excerpt when there are sub results
          excerpt: null,
          subResultCount: result.sub_results.length,
        });
        _.forEach(subResults, subResult => {
          const isTitleOnly = isTitleOnlySubResult(subResult);
          acc.push({
            type: null,
            title: isTitleOnly ? subResult.excerpt : subResult.title,
            url: subResult.url,
            excerpt: isTitleOnly ? null : subResult.excerpt,
            isSubResult: true,
          });
        });
      }, []));
    });
  }, [result, loadCount, setItems]);
  return [items, stats, showMore];
}
