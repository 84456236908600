/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "LUSID Developer Documentation",
  "favicon": "favicon.ico",
  "url": "https://www.lusid.com",
  "baseUrl": "/docs/",
  "organizationName": "finbourne",
  "projectName": "kb-saurus",
  "onBrokenLinks": "warn",
  "onBrokenMarkdownLinks": "warn",
  "staticDirectories": [
    "static"
  ],
  "i18n": {
    "defaultLocale": "en",
    "locales": [
      "en"
    ],
    "path": "i18n",
    "localeConfigs": {}
  },
  "headTags": [
    {
      "tagName": "link",
      "attributes": {
        "rel": "preconnect",
        "href": "https://fonts.googleapis.com"
      }
    },
    {
      "tagName": "link",
      "attributes": {
        "rel": "preconnect",
        "href": "https://fonts.gstatic.com"
      }
    },
    {
      "tagName": "link",
      "attributes": {
        "rel": "stylesheet",
        "href": "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"
      }
    }
  ],
  "markdown": {
    "mermaid": true,
    "format": "mdx",
    "mdx1Compat": {
      "comments": true,
      "admonitions": true,
      "headingIds": true
    }
  },
  "plugins": [
    "/tmp/build/fbc8962a/repo-code/node_modules/docusaurus-plugin-sass/docusaurus-plugin-sass.js",
    "/tmp/build/fbc8962a/repo-code/plugins/webpack-polyfill-plugin.ts",
    [
      "@docusaurus/plugin-content-docs",
      {
        "id": "excel-add-in-docs",
        "path": "docs/excel",
        "routeBasePath": "excel"
      }
    ]
  ],
  "presets": [
    [
      "classic",
      {
        "docs": {
          "id": "api-docs",
          "routeBasePath": "api",
          "path": "docs/api"
        },
        "theme": {
          "customCss": "./src/css/custom.scss"
        }
      }
    ]
  ],
  "themes": [
    "@docusaurus/theme-mermaid"
  ],
  "themeConfig": {
    "image": "finbourne-white.svg",
    "mermaid": {
      "theme": {
        "light": "neutral",
        "dark": "dark"
      },
      "options": {}
    },
    "navbar": {
      "logo": {
        "alt": "LUSID by FINBOURNE",
        "src": "finbourne-white.svg",
        "srcDark": "finbourne-white.svg"
      },
      "items": [
        {
          "type": "search",
          "position": "left",
          "className": "navbar-search-container"
        },
        {
          "label": "Knowledge Base",
          "position": "right",
          "to": "https://support.lusid.com"
        },
        {
          "type": "dropdown",
          "label": "API Reference",
          "to": "/api/intro",
          "items": [
            {
              "label": "Access",
              "to": "/api/access/intro"
            },
            {
              "label": "Configuration store",
              "to": "/api/configuration/intro"
            },
            {
              "label": "Drive",
              "to": "/api/drive/intro"
            },
            {
              "label": "Horizon",
              "to": "/api/horizon/intro"
            },
            {
              "label": "Identity",
              "to": "/api/identity/intro"
            },
            {
              "label": "Insights",
              "to": "/api/insights/intro"
            },
            {
              "label": "Luminesce",
              "to": "/api/luminesce/intro"
            },
            {
              "label": "LUSID",
              "to": "/api/lusid/intro"
            },
            {
              "label": "Notification service",
              "to": "/api/notification/intro"
            },
            {
              "label": "Scheduler",
              "to": "/api/scheduler/intro"
            },
            {
              "label": "Web app",
              "to": "/api/web-app/intro"
            },
            {
              "label": "Workflow service",
              "to": "/api/workflow/intro"
            }
          ],
          "position": "right"
        },
        {
          "type": "dropdown",
          "label": "Extensions",
          "items": [
            {
              "label": "Excel add-in",
              "to": "/excel"
            }
          ],
          "position": "right"
        },
        {
          "label": "Service Status",
          "position": "right",
          "to": "https://status.lusid.com"
        },
        {
          "type": "custom-domain-picker",
          "position": "right"
        }
      ],
      "hideOnScroll": false
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#393A34",
          "backgroundColor": "#f6f8fa"
        },
        "styles": [
          {
            "types": [
              "comment",
              "prolog",
              "doctype",
              "cdata"
            ],
            "style": {
              "color": "#999988",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "namespace"
            ],
            "style": {
              "opacity": 0.7
            }
          },
          {
            "types": [
              "string",
              "attr-value"
            ],
            "style": {
              "color": "#e3116c"
            }
          },
          {
            "types": [
              "punctuation",
              "operator"
            ],
            "style": {
              "color": "#393A34"
            }
          },
          {
            "types": [
              "entity",
              "url",
              "symbol",
              "number",
              "boolean",
              "variable",
              "constant",
              "property",
              "regex",
              "inserted"
            ],
            "style": {
              "color": "#36acaa"
            }
          },
          {
            "types": [
              "atrule",
              "keyword",
              "attr-name",
              "selector"
            ],
            "style": {
              "color": "#00a4db"
            }
          },
          {
            "types": [
              "function",
              "deleted",
              "tag"
            ],
            "style": {
              "color": "#d73a49"
            }
          },
          {
            "types": [
              "function-variable"
            ],
            "style": {
              "color": "#6f42c1"
            }
          },
          {
            "types": [
              "tag",
              "selector",
              "keyword"
            ],
            "style": {
              "color": "#00009f"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "backgroundColor": "hsl(220, 13%, 18%)",
          "color": "hsl(220, 14%, 71%)",
          "textShadow": "0 1px rgba(0, 0, 0, 0.3)"
        },
        "styles": [
          {
            "types": [
              "comment",
              "prolog",
              "cdata"
            ],
            "style": {
              "color": "hsl(220, 10%, 40%)"
            }
          },
          {
            "types": [
              "doctype",
              "punctuation",
              "entity"
            ],
            "style": {
              "color": "hsl(220, 14%, 71%)"
            }
          },
          {
            "types": [
              "attr-name",
              "class-name",
              "maybe-class-name",
              "boolean",
              "constant",
              "number",
              "atrule"
            ],
            "style": {
              "color": "hsl(29, 54%, 61%)"
            }
          },
          {
            "types": [
              "keyword"
            ],
            "style": {
              "color": "hsl(286, 60%, 67%)"
            }
          },
          {
            "types": [
              "property",
              "tag",
              "symbol",
              "deleted",
              "important"
            ],
            "style": {
              "color": "hsl(355, 65%, 65%)"
            }
          },
          {
            "types": [
              "selector",
              "string",
              "char",
              "builtin",
              "inserted",
              "regex",
              "attr-value"
            ],
            "style": {
              "color": "hsl(95, 38%, 62%)"
            }
          },
          {
            "types": [
              "variable",
              "operator",
              "function"
            ],
            "style": {
              "color": "hsl(207, 82%, 66%)"
            }
          },
          {
            "types": [
              "url"
            ],
            "style": {
              "color": "hsl(187, 47%, 55%)"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "textDecorationLine": "line-through"
            }
          },
          {
            "types": [
              "inserted"
            ],
            "style": {
              "textDecorationLine": "underline"
            }
          },
          {
            "types": [
              "italic"
            ],
            "style": {
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "important",
              "bold"
            ],
            "style": {
              "fontWeight": "bold"
            }
          },
          {
            "types": [
              "important"
            ],
            "style": {
              "color": "hsl(220, 14%, 71%)"
            }
          }
        ]
      },
      "additionalLanguages": [],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        }
      ]
    },
    "docs": {
      "sidebar": {
        "hideable": true,
        "autoCollapseCategories": false
      },
      "versionPersistence": "localStorage"
    },
    "colorMode": {
      "defaultMode": "light",
      "disableSwitch": false,
      "respectPrefersColorScheme": false
    },
    "metadata": [],
    "tableOfContents": {
      "minHeadingLevel": 2,
      "maxHeadingLevel": 3
    }
  },
  "baseUrlIssueBanner": true,
  "onBrokenAnchors": "warn",
  "onDuplicateRoutes": "warn",
  "customFields": {},
  "scripts": [],
  "stylesheets": [],
  "clientModules": [],
  "tagline": "",
  "titleDelimiter": "|",
  "noIndex": false
};
