import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { SearchFilter, SearchResultType, useSearch } from './pagefind';
import _ from 'lodash';
import clsx from 'clsx';
import Link from '@docusaurus/Link';
import { useHistory } from '@docusaurus/router';

import './search-bar.scss';

const searchFilterLabels = {
  [SearchFilter.All]: 'All',
  [SearchFilter.API]: 'API',
  [SearchFilter.ExcelAddin]: 'Excel add-in',
};

const searchResultIcons = {
  [SearchResultType.APIEndpoint]: 'settings_applications',
  [SearchResultType.APISchema]: 'package_2',
  [SearchResultType.ExcelFunction]: 'function',
};

const searchResultTitles = {
  [SearchResultType.APIEndpoint]: 'API endpoint',
  [SearchResultType.APISchema]: 'API schema',
  [SearchResultType.ExcelFunction]: 'Excel add-in function',
};

function scrollIntoViewIfNeeded(el: HTMLElement, container: HTMLDivElement) {
  const elBBox = el.getBoundingClientRect();
  const containerBBox = container.getBoundingClientRect();
  if(elBBox.bottom > containerBBox.bottom) {
    container.scrollTop += elBBox.bottom - containerBBox.bottom;
  } else if(elBBox.top < containerBBox.top) {
    container.scrollTop += elBBox.top - containerBBox.top;
  }
}

export default function SearchBar() {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const showMoreRef = useRef<HTMLButtonElement>(null);
  const itemRefs = useRef<HTMLAnchorElement[]>([]);
  const resultsRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState(SearchFilter.All);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchResults, searchStats, showMore] = useSearch(query, activeFilter);
  useEffect(() => {
    const onStartSearchKeybind = (ev: KeyboardEvent) => {
      const targetTag = (ev.target as HTMLElement).tagName;
      if(targetTag === 'INPUT' || targetTag === 'TEXTAREA') {
        return;
      }
      if(ev.key === '/') {
        ev.preventDefault();
        setIsOpen(true);
        searchInputRef.current?.focus();
      }
    };
    const onKeyDown = (ev: KeyboardEvent) => {
      switch(ev.key) {
        case 'ArrowUp':
          ev.preventDefault();
          setActiveIndex(activeIndex === 0 ? searchResults.length : (activeIndex - 1));
          return;
        case 'ArrowDown':
          ev.preventDefault();
          setActiveIndex((activeIndex + 1) % (searchResults.length + 1));
          return;
        case 'Enter': {
          if(activeIndex === searchResults.length) {
            showMore && showMore();
            return;
          }
          const activeResult = searchResults[activeIndex];
          if(activeResult) {
            setIsOpen(false);
            setQuery('');
            history.push(activeResult.url);
            searchInputRef.current?.blur();
            return;
          }
          return;
        }
        case 'Escape':
          searchInputRef.current?.blur();
          setIsOpen(false);
          setQuery('');
          return;
      }
    };
    if(isOpen) {
      window.addEventListener('keydown', onKeyDown);
      window.removeEventListener('keydown', onStartSearchKeybind);
    } else {
      window.removeEventListener('keydown', onKeyDown);
      window.addEventListener('keydown', onStartSearchKeybind);
    }
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keydown', onStartSearchKeybind);
    };
  }, [activeIndex, isOpen, query, searchResults, setActiveIndex]);
  useEffect(() => {
    if(activeIndex === searchResults.length) {
      showMore && scrollIntoViewIfNeeded(showMoreRef.current!, resultsRef.current!);
      return;
    }
    const currentItem = itemRefs.current[activeIndex];
    if(currentItem) {
      scrollIntoViewIfNeeded(currentItem, resultsRef.current!);
    }
  }, [activeIndex]);
  useEffect(() => {
    setQuery('');
    setActiveIndex(0);
    setActiveFilter(SearchFilter.All);
    document.body.classList.toggle('search-open', isOpen);
  }, [isOpen]);
  const onTriggerFocus = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onVeilClick = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onLinkClick = useCallback(() => {
    setIsOpen(false);
    setQuery('');
  }, [setIsOpen]);
  const onFilterClick = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setActiveIndex(0);
    setActiveFilter(ev.target.value as SearchFilter);
  }, [setActiveFilter]);
  const onSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setActiveIndex(0);
    setQuery(ev.target.value);
  }, [setQuery]);
  return (
    <>
      { isOpen && <div className="search-bar__veil" onClick={onVeilClick} /> }
      <div
        ref={rootRef}
        className={clsx({
          'search-bar': true,
          'search-bar_open': isOpen,
        })}
      >
        { isOpen && (
          <div className="search-bar__results-container">
            <div className="search-bar__results-filters">
              {
                _.map(SearchFilter, resource => (
                  <label
                    className={clsx({
                      'search-bar__results-filter': true,
                      'search-bar__results-filter_active': activeFilter === resource,
                    })}
                    key={resource}
                  >
                    <input
                      className="search-bar__results-filter-input"
                      type="radio"
                      value={resource}
                      checked={activeFilter === resource}
                      onChange={onFilterClick}
                    />
                    <span className="search-bar__results-filter-label">
                      { searchFilterLabels[resource] } ({ searchStats.counts[resource] })
                    </span>
                  </label>
                ))
              }
            </div>
            <div ref={resultsRef} className="search-bar__results">
              { searchResults.length === 0 && query === '' && <span className="search-bar__results-fallback">Start typing to see results</span> }
              { searchResults.length === 0 && query !== '' && <span className="search-bar__results-fallback">No results found</span> }
              { _.map(searchResults, (result, index) => (
                <Link
                  key={index}
                  ref={el => itemRefs.current[index] = el!}
                  className={clsx({
                    'search-bar__result': true,
                    'search-bar__result_title-only': !result.excerpt,
                    'search-bar__result_sub-result': result.isSubResult,
                    'search-bar__result_active': index === activeIndex,
                  })}
                  href={result.url}
                  onClick={onLinkClick}
                >
                  <h3 className="search-bar__result-title">
                    <span dangerouslySetInnerHTML={{ __html: result.title }} />
                    { (result.subResultCount || 0) > 5 && (
                      <span className="search-bar__result-subtitle">
                        Showing top 5 results of { result.subResultCount }
                      </span>
                    ) }
                  </h3>
                  { result.type && (
                    <span
                      className="search-bar__result-icon material-symbols-outlined"
                      title={searchResultTitles[result.type]}
                    >
                      { searchResultIcons[result.type] }
                    </span>
                  ) }
                  { result.excerpt && (
                    <p
                      className="search-bar__result-excerpt"
                      dangerouslySetInnerHTML={{ __html: result.excerpt }}
                    />
                  ) }
                </Link>
              )) }
              { searchResults.length > 0 && (
                <button
                  ref={showMoreRef}
                  className={clsx({
                    'search-bar__show-more': true,
                    'search-bar__show-more_active': activeIndex === searchResults.length,
                  })}
                  disabled={!showMore}
                  onClick={showMore || undefined}
                >
                  Show more
                </button>
              ) }
            </div>
          </div>
        ) }
        <label className="search-bar__trigger" onFocus={onTriggerFocus}>
          <span className="material-symbols-outlined">search</span>
          <input
            ref={searchInputRef}
            autoComplete="off"
            className="search-bar__trigger-input"
            placeholder="Search content"
            value={query}
            onChange={onSearch}
          />
          { !isOpen && <kbd>/</kbd> }
        </label>
      </div>
    </>
  );
}
