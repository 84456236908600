import React from 'react';

import './footer.scss';

function Footer(): JSX.Element {
  return (
    <footer className="footer">
      © <a className="footer__link" href="https://www.finbourne.com">FINBOURNE Technology</a>
    </footer>
  );
}

export default React.memo(Footer);
